require('@rails/ujs').start()
require('turbolinks').start()
require('@rails/activestorage').start()
require('chart.js')


const images = require.context('../images', true)
const imagePath = (name) => images(name, true)


// import javascripts
import $ from 'jquery'
import 'bootstrap'

// import stylesheets
import 'chart.js/dist/Chart.css'
import '../stylesheets/application'

// import shared
import '../shared/bootstrap.js'
import '../shared/clipboard.js'
import '../shared/turbolinks.js'
import '../shared/bs_custom_file_input.js'
import '../shared/ajax.js'
import '../shared/js_noaction.js'
import '../shared/select_on_focus.js'
import '../shared/transactions_select_type.js'


window.jQuery = $
window.$ = $


document.addEventListener('turbolinks:load', function() {
	let menu = false

	function menu_hide() {
		$('.menu-mobile').animate({ left: '-285px' }, 200)
		$('body').animate({ left: '0px' }, 200)
		menu = false
	}

	$('.menu-mobile-button').click(function() {
		if (menu === false) {
			$('.menu-mobile').animate({ left: '0px' }, 200)
			$('body').animate({ left: '285px' }, 200)
			menu = true
		} else {
			menu_hide()
		}
	})

	$('.menu-mobile').on('click', '.menu-mobile__link', function() {
		menu_hide()
	})
})

document.addEventListener('turbolinks:load', function() {
	var element_menu = $('.menu')
	var speed_factor = 3

	$('.menu__list').on('click', '.js-scroll', function(event) {
		event.preventDefault()

		let offset_top = $('#' + $(this).data('scroll')).offset().top - element_menu.height()
		$('body, html').animate({ scrollTop: offset_top }, Math.abs(offset_top - $(window).scrollTop()) / speed_factor)
		location.hash = $(this).data('scroll')

		return false
	})
})

// office/automatic_stock_profit

document.addEventListener('turbolinks:load', function() {
	$('.asp-recommendations').tooltip(
		{
			content: function()
			{
				return $('.asp-tooltip').html()
			},
			hide:
				{
					delay: 50
				},
			show:
				{
					delay: 50
				},
			track: false
		}
	)
})

document.addEventListener('turbolinks:load', ()=> {
	let element = document.getElementById('asp-chart-1')

	if (element) {
		let context = element.getContext('2d')

		let data = {
			datasets: [{
				data: [element.dataset.part1, element.dataset.part2, element.dataset.part3, element.dataset.part4, element.dataset.part5, element.dataset.part6],
				backgroundColor: [
					'#5bb75b',
					'rgba(0, 0, 0, 0.1)',
					'#5bb75b',
					'rgba(0, 0, 0, 0.1)',
					'#5bb75b',
					'rgba(0, 0, 0, 0.1)'
				]
			}]
		}

		let chart = new Chart(context, {
			type: 'doughnut',
			data: data,
			options: {
				circumference: 1.8 * Math.PI,
				cutoutPercentage: 90,
				events: [],
				legend: {
					display: false
				},
				rotation: 0.6 * Math.PI,
				tooltips: {
					enabled: false
				}
			}
		})
	}
})

document.addEventListener('turbolinks:load', ()=> {
	let element = document.getElementById('asp-chart-2')

	if (element) {
		let context = element.getContext('2d')

		let data = {
			datasets: [{
				data: [element.dataset.part1, element.dataset.part2, element.dataset.part3, element.dataset.part4, element.dataset.part5, element.dataset.part6],
				backgroundColor: [
					'#5bb75b',
					'rgba(0, 0, 0, 0.1)',
					'#5bb75b',
					'rgba(0, 0, 0, 0.1)',
					'#5bb75b',
					'rgba(0, 0, 0, 0.1)'
				]
			}]
		}

		let chart = new Chart(context, {
			type: 'doughnut',
			data: data,
			options: {
				circumference: 1.8 * Math.PI,
				cutoutPercentage: 90,
				events: [],
				legend: {
					display: false
				},
				rotation: 0.6 * Math.PI,
				tooltips: {
					enabled: false
				}
			}
		})
	}
})

document.addEventListener('turbolinks:load', ()=> {
	let element = document.getElementById('asp-chart-3')

	if (element) {
		let context = element.getContext('2d')

		let data = {
			datasets: [{
				data: [element.dataset.part1, element.dataset.part2],
				backgroundColor: [
					'#5bb75b'
				]
			}]
		}

		let chart = new Chart(context, {
			type: 'doughnut',
			data: data,
			options: {
				circumference: 1.8 * Math.PI,
				cutoutPercentage: 90,
				events: [],
				legend: {
					display: false
				},
				rotation: 0.6 * Math.PI,
				tooltips: {
					enabled: false
				}
			}
		})
	}
})

document.addEventListener('turbolinks:load', function() {
	if($('.asp-application-form__photo-id-type').val() !== '') {
		$('.asp-application-form__photo-id-type-group[data-name=' + $('.asp-application-form__photo-id-type').val() + ']').show()
	}

	$('.asp-application-form__photo-id-type').on('change', function() {
		$('.asp-application-form__photo-id-type-group').hide()

		if($(this).val() !== '') {
			$('.asp-application-form__photo-id-type-group[data-name=' + $(this).val() + ']').show()
		}
	})
})

document.addEventListener('turbolinks:load', function() {
	if($('.asp-application-form__employment-status').val() !== '') {
		$('.asp-application-form__employment-status-group[data-name=' + $('.asp-application-form__employment-status').val() + ']').show()
	}

	$('.asp-application-form__employment-status').on('change', function() {
		$('.asp-application-form__employment-status-group').hide()

		if($(this).val() !== '') {
			$('.asp-application-form__employment-status-group[data-name=' + $(this).val() + ']').show()
		}
	})
})

document.addEventListener('turbolinks:load', function() {
	if($('.asp-application-form__applicant-location').val() !== '') {
		$('.asp-application-form__applicant-location-group[data-name=' + $('.asp-application-form__applicant-location').val() + ']').show()
	}

	$('.asp-application-form__applicant-location').on('change', function() {
		$('.asp-application-form__applicant-location-group').hide()

		if($(this).val() !== '') {
			$('.asp-application-form__applicant-location-group[data-name=' + $(this).val() + ']').show()
		}
	})
})

// office/education

// document.addEventListener('turbolinks:load', function() {
// 	$('.education-certificate-progress__progressbar').progressbar({value: $('.education-certificate-progress__progressbar').data('value')})
// })

// office/partners

document.addEventListener('turbolinks:load', function() {
	$('.checkbox-partners-inactive').on('change', function () {
		$('.form-partners').submit()
	})
})

// office/structure

document.addEventListener('turbolinks:load', function() {
	$('body').on('mouseenter mouseleave', '.structure__group__items__item-personal_full', function() {
		$(this).toggleClass('structure__group__items__item_highlight')
	})
})

document.addEventListener('turbolinks:load', function() {
	$('body').on('click', '.structure__group__items__item_full', function() {
		var parent = $('.structure__group__items__item_' + $(this).data('parent-uid'))
		var children = $('.structure__group__items__item_parent-' + $(this).data('uid'))

		$('.structure__group__items__item_full').removeClass('structure__group__items__item_highlight structure__group__items__item_selected')
		$(this).addClass('structure__group__items__item_highlight').toggleClass('structure__group__items__item_selected')
		parent.toggleClass('structure__group__items__item_highlight structure__group__items__item_selected')
		children.toggleClass('structure__group__items__item_highlight structure__group__items__item_selected')
	})
})

document.addEventListener('turbolinks:load', function() {
	$('body').on('mouseenter mouseleave', '.structure__group__items__item_full', function() {
		if (! $(this).hasClass('structure__group__items__item_selected')) {
			$(this).toggleClass('structure__group__items__item_highlight')
		}

		var parent = $('.structure__group__items__item_' + $(this).data('parent-uid'))

		if (! parent.hasClass('structure__group__items__item_selected')) {
			parent.toggleClass('structure__group__items__item_highlight')
		}

		var children = $('.structure__group__items__item_parent-' + $(this).data('uid'))

		children.each(function() {
			if (! $(this).hasClass('structure__group__items__item_selected')) {
				$(this).toggleClass('structure__group__items__item_highlight')
			}
		})
	})
})


// office/trading_signals

// document.addEventListener('turbolinks:load', function() {
// 	$('.f-life-annuity__progressbar').progressbar({value: $('.f-life-annuity__progressbar').data('value')})
// })


// office/system/trading/index

// document.addEventListener('turbolinks:load', function() {
// 	$('.f-life-annuity__progressbar').progressbar({value: $('.f-life-annuity__progressbar').data('value')})
// })


// office/system/trading/trading_signals

document.addEventListener('turbolinks:load', function() {
	$('.trading-signal-form__price-in').on('focusout', function() {
		$('.trading-signal-form__price-stop').val($(this).val())
	})
})


// system/cards

document.addEventListener('turbolinks:load', function() {
	$('[data-behavior="card-not-verify"]').on('click', function(e) {
		e.preventDefault()

		var card_id = $(this).data('card-id')

		swal(
			{
				title: 'Card is not verified',
				text: 'Reason:',
				type: 'input',
				allowOutsideClick: true,
				closeOnConfirm: false,
				showCancelButton: true,
				showLoaderOnConfirm: true,
				animation: 'slide-from-top'
			},
			function(value) {
				if (value === false) return false

				$.post('/en/system/cards/' + card_id + '/not-verify/', { message: value })

				swal('Successfully!', null, 'success')
			}
		)
	})
})


// system/documents

document.addEventListener('turbolinks:load', function() {
	$('[data-behavior="document-not-verify"]').on('click', function(e) {
		e.preventDefault()

		var document_id = $(this).data('document-id')

		swal(
			{
				title: 'Document is not verified',
				text: 'Reason:',
				type: 'input',
				allowOutsideClick: true,
				closeOnConfirm: false,
				showCancelButton: true,
				showLoaderOnConfirm: true,
				animation: 'slide-from-top'
			},
			function(value) {
				if (value === false) return false

				$.post('/en/system/documents/' + document_id + '/not-verify/', { message: value })

				swal('Successfully!', null, 'success')
			}
		)
	})
})


// system/yandex_money_wallets

document.addEventListener('turbolinks:load', function() {
	$('[data-behavior="yandex-money-wallet-not-verify"]').on('click', function(e) {
		e.preventDefault()

		var yandex_money_wallet_id = $(this).data('yandex-money-wallet-id')

		swal(
			{
				title: 'Yandex.Money wallet is not verified',
				text: 'Reason:',
				type: 'input',
				allowOutsideClick: true,
				closeOnConfirm: false,
				showCancelButton: true,
				showLoaderOnConfirm: true,
				animation: 'slide-from-top'
			},
			function(value) {
				if (value === false) return false

				$.post('/en/system/yandex-money-wallets/' + yandex_money_wallet_id + '/not-verify/', { message: value })

				swal('Successfully!', null, 'success')
			}
		)
	})
})


// system/users

document.addEventListener('turbolinks:load', function() {
	$('.s-users__item__fullname').tooltip({
		content: function() {
			return $('.s-users__item__fullname__tooltip_' + $(this).data('uid')).html()
		},
		hide: {
			delay: 50
		},
		position: {
			my: 'left bottom',
			at: 'right top'
		},
		show: {
			delay: 50
		},
		track: false
	})

	$('.s-users').on('click', '.s-users__item__fullname', function() {
		$('.s-users__item__fullname__tooltip_' + $(this).data('uid')).slideToggle('fast')
	})
})


// app/game

// document.addEventListener('turbolinks:load', function() {
// 	$('.game-personals__progress').progressbar({value: $('.game-personals__progress').data('value')})
// })
//
// document.addEventListener('turbolinks:load', function() {
// 	$('.game-levels__item__progress_1').progressbar({value: $('.game-levels__item__progress_1').data('value')})
// })
//
// document.addEventListener('turbolinks:load', function() {
// 	$('.game-levels__item__progress_2').progressbar({value: $('.game-levels__item__progress_2').data('value')})
// })
//
// document.addEventListener('turbolinks:load', function() {
// 	$('.game-levels__item__progress_3').progressbar({value: $('.game-levels__item__progress_3').data('value')})
// })
//
// document.addEventListener('turbolinks:load', function() {
// 	$('.game-levels__item__progress_4').progressbar({value: $('.game-levels__item__progress_4').data('value')})
// })
//
// document.addEventListener('turbolinks:load', function() {
// 	$('.game-levels__item__progress_5').progressbar({value: $('.game-levels__item__progress_5').data('value')})
// })
//
// document.addEventListener('turbolinks:load', function() {
// 	$('.game-levels__item__progress_6').progressbar({value: $('.game-levels__item__progress_6').data('value')})
// })
//
// document.addEventListener('turbolinks:load', function() {
// 	$('.game-levels__item__progress_7').progressbar({value: $('.game-levels__item__progress_7').data('value')})
// })
//
// document.addEventListener('turbolinks:load', function() {
// 	$('.game-levels__item__progress_8').progressbar({value: $('.game-levels__item__progress_8').data('value')})
// })
//
// document.addEventListener('turbolinks:load', function() {
// 	$('.game-levels__item__progress_9').progressbar({value: $('.game-levels__item__progress_9').data('value')})
// })
//
// document.addEventListener('turbolinks:load', function() {
// 	$('.game-3-levels__item__progress_1').progressbar({value: $('.game-3-levels__item__progress_1').data('value')})
// })
//
// document.addEventListener('turbolinks:load', function() {
// 	$('.game-3-levels__item__progress_2').progressbar({value: $('.game-3-levels__item__progress_2').data('value')})
// })
//
// document.addEventListener('turbolinks:load', function() {
// 	$('.game-3-levels__item__progress_3').progressbar({value: $('.game-3-levels__item__progress_3').data('value')})
// })
//
// document.addEventListener('turbolinks:load', function() {
// 	$('.game-3-levels__item__progress_4').progressbar({value: $('.game-3-levels__item__progress_4').data('value')})
// })
